import { Navigate, Outlet } from 'react-router-dom';

import { usesAuthenticated } from '../../hooks';

function ProtectedRoutes() {
  const { isAuthenticated } = usesAuthenticated();

  return <div>{isAuthenticated ? <Outlet /> : <Navigate to="/login" />}</div>;
}

export default ProtectedRoutes;
