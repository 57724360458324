import { Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { routes } from './routes';
import { TOKEN_OBJ } from './constants';

const router = createBrowserRouter(routes);

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL;

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
});

const authLink = setContext((_, { headers }) => {
  let token = '';
  const tokenObjStr = localStorage.getItem(TOKEN_OBJ);

  if (tokenObjStr) {
    const tokenObj = JSON.parse(tokenObjStr);
    token = tokenObj.accessToken;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          window.location.href = '/logout';
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  uri: GRAPHQL_API_URL,
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </ApolloProvider>
  );
};

export default App;
