import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import React from 'react';
import { Button, Result } from 'antd';

import ProtectedRoutes from './components/protected';

const Auth = React.lazy(() => import('./components/auth'));
const AuthLayout = React.lazy(() => import('./components/layouts/AuthLayout'));
const AppLayout = React.lazy(() => import('./components/layouts/AppLayout'));
const Logout = React.lazy(() => import('./components/logout'));
const Callback = React.lazy(() => import('./components/callback'));
const FlightsPage = React.lazy(() => import('./pages/flights/FlightsPage'));

const redirectPath = process.env.REACT_APP_ORY_REDIRECT_PATH || 'callback';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: 'login',
            element: <Auth />,
          },
          {
            path: redirectPath,
            element: <Callback />,
          },
          {
            path: 'logout',
            element: <Logout />,
          },
        ],
      },
      {
        path: '',
        element: <ProtectedRoutes />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                path: '',
                element: <FlightsPage />,
              },
              {
                path: '*',
                element: (
                  <Result
                    status={404}
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary">Back Home</Button>}
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];
